<template>
    <v-container fluid>
        <div class="loading" v-if="loading">
            <div class="text-center">
                <v-progress-circular
                        indeterminate
                        color="primary"
                ></v-progress-circular>
            </div>
        </div>
        <div v-else-if="album">
            <v-card>
                <v-card-title>
                    <v-avatar size="56">
                        <img
                                alt="user"
                                :src="album.image"
                        >
                    </v-avatar>
                    <p class="ml-3">
                        {{ album.name }}
                    </p>
                </v-card-title>

                <v-card-text>
                    <router-link :to="'/artist/' + album.artist_id">{{ album.artist.name }}</router-link>
                    <v-list
                            dense
                            subheader
                    >
                        <v-subheader>Tracks</v-subheader>
                        <Track context="album" v-for="track in album.track_objects" :key="track.track_id" :track="track.track" />
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>

<script>
    import Track from '../components/Elements/Track'

    export default {
        name: "Album",
        components: {Track},
        data: function() {
            return {
                album: null,
                loading: false
            }
        },
        async mounted () {
            this.$store.commit('setTitle', 'Album')
            const albumId = this.$route.params.album_id

            this.loading = true
            const result = await axios.get('/data/album/' + albumId)
            this.album = result.data
            this.loading = false

            this.$store.commit('setTitle', this.album.name)
        },
        computed: {
            layout() {
                return this.$route.meta.layout || 'AppLayout'
            }
        }
    }
</script>

<style scoped>

</style>