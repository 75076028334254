<template>
    <v-container class="trackInfo">
        <template v-if="trackId">
            <template v-if="trackData">
                <img :src="trackData.album.image"/><br/>
                <span class="title">{{ trackData.name }}</span><br/>
                <span class="artist">von {{ trackData.album.artist.name }}</span>
                <br/><br/>
                <template v-if="getTrackEntry(trackData)">
                    Song ist bereits hinzugefügt!
                </template>
                <template v-else>
                    <v-btn
                            color="primary"
                            :disabled="adding"
                            text
                            @click="addCustomTrack(trackData)"
                    >
                        Hinzufügen
                    </v-btn>
                </template>
            </template>
            <template v-else>
                Bitte warten...
            </template>
        </template>
        <template v-else>
            Song nicht gefunden.
        </template>
    </v-container>
</template>

<script>
    export default {
        name: "ShareTarget",
        async mounted () {
            this.$store.commit('setTitle', 'Song hinzufügen')

            if (this.trackId) {
                const data = await axios.get('/data/track/' + this.trackId);

                console.log(data);
                this.trackData = data.data.track;
            }

        },
        data: function () {
            return {
                trackData: null,
                adding: false
            }
        },
        methods: {
            addCustomTrack: function (id) {
                this.addTrack(id)

                this.$router.push('/room')
            },
            getTrackEntry (track) {
                var entry = null;
                this.$store.state.roomState.queue.forEach(e => {
                    if (e.track_id === track.track_id) {
                        entry = e;
                    }
                })

                return entry;
            }
        },
        computed: {
            params: function () {
                return this.$route.query;
            },
            trackId: function () {
                if (this.$route.query.text) {
                    const regex = /\/track\/(.*?)\?/
                    const songLink = this.$route.query.text;

                    const matches = regex.exec(songLink)

                    if (matches.length > 1) {
                        return matches[1]
                    }
                }
                return null;
            }
        }
    }
</script>

<style scoped lang="stylus">
    .trackInfo
        text-align center

        .title
            font-weight bold

        img
            width: 80%;
</style>