<template>
    <v-container fluid>
        <div class="loading" v-if="loading">
            <div class="text-center">
                <v-progress-circular
                        indeterminate
                        color="primary"
                ></v-progress-circular>
            </div>
        </div>
        <div v-else-if="artist">
            <v-card>
                <v-card-title>
                    <v-avatar size="56">
                        <img
                                alt="user"
                                :src="artist.image"
                        >
                    </v-avatar>
                    <p class="ml-3">
                        {{ artist.name }}
                    </p>
                </v-card-title>

                <v-card-text>
                    <v-list
                            dense
                            subheader
                    >
                        <v-subheader>Top Tracks</v-subheader>
                        <Track context="artist" v-for="track in artist.top_track_objects.slice(0,5)" :key="track.track_id" :track="track" />
                    </v-list>
                </v-card-text>

                <v-card-text>
                    <v-list
                            dense
                            subheader
                    >
                        <v-subheader>Alben</v-subheader>
                        <v-list-item v-for="album in artist.albums" :key="album.album_id" :to="'/album/' + album.album_id">
                            <v-list-item-avatar>
                                <v-img
                                        :alt="`${album.name} cover`"
                                        :src="album.image"
                                ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="album.name"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>

<script>
    import Track from '../components/Elements/Track'

    export default {
        name: "Artist",
        components: {Track},
        data: function() {
            return {
                artist: null,
                loading: false
            }
        },
        async mounted () {
            this.$store.commit('setTitle', 'Artist')
            const artistId = this.$route.params.artist_id

            this.loading = true
            const result = await axios.get('/data/artist/' + artistId)
            this.artist = result.data
            this.loading = false

            this.$store.commit('setTitle', this.artist.name)
        },
        computed: {
            layout() {
                return this.$route.meta.layout || 'AppLayout'
            }
        }
    }
</script>

<style scoped>

</style>