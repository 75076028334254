<template>
    <div>
        <v-container>
            <v-text-field clearable v-model="searchText" @keypress.enter="search()">
                <v-icon
                        slot="append"
                        color="primary"
                        @click="search()"
                >
                    mdi-magnify
                </v-icon>
            </v-text-field>
            <v-container v-if="searchText && !searchLoading && resultSet.query !== searchText" class="autocomplete">
                <v-list-item
                        v-for="suggestion in suggestions"
                        :key="suggestion"
                        class="list-item-small"
                        @click="setSearch(suggestion)"
                >

                    <v-list-item-content class="list-content-small">
                        <v-list-item-title v-text="suggestion"></v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-container>
        </v-container>


        <template v-if="!searchText || searchText.length <= 2">
            <v-list subheader>
                <v-subheader>Recent searches</v-subheader>

                <v-list-item
                        v-for="searchEntry in searchHistory"
                        :key="searchEntry"
                        @click="setSearch(searchEntry)"
                >

                    <v-list-item-content>
                        <v-list-item-title v-text="searchEntry"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                        <v-icon>
                            mdi-magnify
                        </v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </template>
        <template v-else>
            <v-container v-if="!searchLoading && resultSet.query !== searchText">
<!--                <v-list-item-->
<!--                        v-for="suggestion in suggestions"-->
<!--                        :key="suggestion"-->
<!--                        @click="setSearch(suggestion)"-->
<!--                >-->

<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title v-text="suggestion"></v-list-item-title>-->
<!--                    </v-list-item-content>-->

<!--                    <v-list-item-icon>-->
<!--                        <v-icon>-->
<!--                            mdi-magnify-->
<!--                        </v-icon>-->
<!--                    </v-list-item-icon>-->
<!--                </v-list-item>-->
            </v-container>
            <v-container v-else-if="searchLoading">
                <div class="text-center">
                    <v-progress-circular
                            indeterminate
                            color="primary"
                    ></v-progress-circular>
                </div>
            </v-container>
            <v-container v-else-if="error">
                <div class="text-center">
                    Bei der Suche ist ein Fehler aufgetreten. <a @click="search">Bitte versuche es erneut.</a>
                </div>
            </v-container>
            <v-container v-else>
                <v-tabs v-model="activeTab" fixed-tabs>
                    <v-tab v-if="resultSet.tracks.length > 0">Songs</v-tab>
                    <v-tab v-if="resultSet.artists.length > 0">Artists</v-tab>
                    <v-tab v-if="resultSet.albums.length > 0">Albums</v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab">
                    <v-tab-item v-if="resultSet.tracks.length > 0">
                        <v-list
                                dense
                        >
                            <Track context="search" v-for="track in resultSet.tracks" :key="track.track_id" :track="track"/>
                        </v-list>
                    </v-tab-item>
                    <v-tab-item v-if="resultSet.artists.length > 0">
                        <v-list dense>
                            <v-list-item v-for="artist in resultSet.artists" :key="artist.artist_id"
                                         :to="'/artist/' + artist.artist_id">
                                <v-list-item-avatar>
                                    <v-img
                                            :alt="`${artist.name} cover`"
                                            :src="artist.image"
                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="artist.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                    <v-tab-item v-if="resultSet.albums.length > 0">
                        <v-list dense>
                            <v-list-item v-for="album in resultSet.albums" :key="album.album_id"
                                         :to="'/album/' + album.album_id">
                                <v-list-item-avatar>
                                    <v-img
                                            :alt="`${album.name} cover`"
                                            :src="album.image"
                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="album.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </template>

    </div>
</template>

<script>
    import _debounce from 'lodash/debounce'
    import Track from '../components/Elements/Track'

    export default {
        name: "Search",
        components: {Track},
        data: () => ({
            searchHistory: [],
            searchText: '',
            searchStatus: '',
            currentResultsFor: '',
            resultSet: {
                tracks: [],
                albums: [],
                artists: [],
                query: 'undefined'
            },
            suggestions: [],
            searchLoading: false,
            activeTab: '',
            clickOnHistory: false,
            error: false
        }),
        computed: {
            searchDone: function () {
                return this.searchText === this.resultSet.query
            }
        },
        mounted () {
            this.$store.commit('setTitle', 'Search')

            var searchHistory = JSON.parse(localStorage.getItem('searchHistory'));

            if (searchHistory && searchHistory.length >= 1) {
                this.searchHistory = searchHistory
            }

            if (this.$route.params.query) {
                this.clickOnHistory = true
                this.searchText = this.$route.params.query
            }
        },
        methods: {
            setSearch: function (text) {
                this.clickOnHistory = true
                this.searchText = text
                this.search();
            },
            onChange: function (value) {
                console.log('change to ', value)
            },
            search: async function () {
                this.searchStatus = 'Searching...'
                this.searchLoading = true;
                this.$router.push('/search/' + encodeURIComponent(this.searchText))

                this.suggestions = []
                try {
                    this.suggestions = [];
                    const tracks = await axios.get('/data/search?query=' + this.searchText)

                    if (this.searchHistory.indexOf(this.searchText) === - 1) {
                        this.searchHistory = this.searchHistory.slice(0, 10)

                        this.searchHistory = [this.searchText, ...this.searchHistory]

                        localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))
                    }

                    if (tracks.data.redirect) {
                        this.$router.push({path: tracks.data.redirect}) // -> /user/123
                        return;
                    }

                    this.resultSet = {
                        tracks: tracks.data.tracks,
                        albums: tracks.data.albums,
                        artists: tracks.data.artists,
                        query: tracks.data.query
                    }

                    this.error = false;
                    this.searchLoading = false;
                } catch (e) {
                    this.error = true;
                    this.searchLoading = false;

                    this.resultSet = {
                        tracks: [],
                        albums: [],
                        artists: [],
                        query: this.searchText
                    }
                }
            },
            async loadSuggestions (query) {
                const tracks = await axios.get('/data/autocomplete?query=' + query)

                if (query === this.searchText) {
                    this.suggestions = tracks.data.queries

                    if (this.suggestions.length === 0) {
                        this.debouncedSearch()
                    }
                }
            }
        },
        created () {
            this.debouncedSearch = _debounce(this.search, 2500)
        },
        watch: {
            searchText: function (newSearch, oldSearch) {
                if (! newSearch || newSearch === '' || this.resultSet.query === newSearch) {
                    this.suggestions = []
                    return;
                }
                if (newSearch.length > 2 && ! this.searchLoading) {
                    this.loadSuggestions(newSearch)
                }
                else {
                    this.suggestions = []
                }
            }
        }
    }
</script>

<style lang="stylus">
    .v-select.v-select--is-menu-active .v-input__icon--append .v-icon
        transform none !important

    .v-slide-group__prev--disabled
        display none !important

    .v-text-field__details
        display none !important

    .container.autocomplete {
        padding-top 0

        .list-content-small {
            padding 4px 0
        }

        .list-item-small {
            padding: 0
        }
    }
</style>