<template>
    <v-container>
        <v-card>
            <v-card-title>Freunde einladen</v-card-title>
            <v-card-text>
                Lade deine Freunde ein!<br />
                Code: {{ roomCode }}
            </v-card-text>
            <v-card-actions>
                <v-btn
                        color="primary"
                        text
                        :href="'https://wa.me/?text=' + roomLink"
                        target="_blank"
                >
                    Einladung versenden
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card>
          <v-card-title>Berechtigungen</v-card-title>
          <v-card-text>
            <ul class="permissions">
              <li><v-icon color="primary">{{ permissions.allowAdd ? 'mdi-check' : 'mdi-lock' }}</v-icon> Songs hinzufügen</li>
              <li><v-icon color="primary">{{ permissions.allowVote ? 'mdi-check' : 'mdi-lock' }}</v-icon> Für Songs abstimmen</li>
<!--              <li><v-icon color="primary">{{ permissions.autoConfirm ? 'mdi-check' : 'mdi-lock' }}</v-icon> Songs automatisch freischalten</li>-->
              <li v-if="permissions.admin"><v-icon color="primary">{{ permissions.admin ? 'mdi-check' : 'mdi-lock' }}</v-icon> Admin</li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card>
            <v-card-title>Raum</v-card-title>
            <v-card-actions>
                <v-btn
                        color="error"
                        text
                        @click="exitRoom"
                >
                    Raum verlassen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: "Settings",
        mounted () {
            this.$store.commit('setTitle', 'Einstellungen')
        },
        methods: {
            exitRoom() {
                localStorage.removeItem('roomHash')
                localStorage.removeItem('client_id')
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

                location.href = process.env.VUE_APP_CLIENT_URL
            },
            reloadApp() {
                if (window.serviceWorker) {
                    window.serviceWorker.update()
                }
            }
        },
        computed: {
            permissions() {
              return this.$store.state.roomState.access
            },
            roomCode() {
                return this.$store.state.roomState.host ? this.$store.state.roomState.host.code : ''
            },
            roomLink() {
                return this.$store.state.roomState.host ?
                    encodeURIComponent(process.env.VUE_APP_CLIENT_URL + "?hash=" + this.$store.state.roomState.host.hash)
                    : ''
            },
            layout() {
                return this.$route.meta.layout || 'AppLayout'
            },
            hostLink() {
                return process.env.VUE_APP_HOST_URL
            }
        }
    }
</script>

<style scoped lang="stylus">
    .v-card
        margin-bottom 20px

    .permissions
      list-style-type none
      li
        padding-bottom 10px
</style>