<template>
    <v-container>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Was ist ManyDJs?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Mit ManyDJs kann eine Spotify Playlist einfach von mehreren Personen erstellt werden.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Wieso verschwinden Songs nachdem sie gespielt wurden?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Alle Songs werden nachdem sie gespielt wurden für eine gewisse Zeit gesperrt damit nicht immer dieselbe Musik läuft.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Ich habe einen Song hinzugefügt, doch er taucht nicht auf?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Der Host kann einstellen dass Songs zuerst durch ihn freigegeben werden müssen.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Wieso, weshalb, warum?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    ManyDJs wurde von Patrick Wyser und Andi Moser im Zuge einer Projektarbeit entwickelt.<br /><br />
                    Wir hatten beide schon mehrmals das Bedürfnis eine Playlist von mehreren Personen gemeinsam zusammenzustellen.
                    Nach tagelanger Arbeit und Fehlersuche ist es nun soweit und die ManyDJs App ist fertiggestellt.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Welche Daten speichert ihr über mich?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Nur das aller Wichtigste:
                    <ul>
                        <li>Dein Name wie du ihn bei Spotify angegeben hast.</li>
                        <li>Sog. API Keys mit welchen wir in deinem Namen bei Spotify nach Musik suchen.</li>
                        <li>Beim Login mit Spotify werden evtl. Cookies auf deinem Gerät gespeichert. Leider haben wir über diese keine Kontrolle bzw. sie sind nötig für das Login via Spotify</li>
                    </ul>
                    Was wir alles nicht machen:
                    <ul>
                        <li>Weitere Daten von deinem Spotify Account laden - nur dein Name!</li>
                        <li>Wir ändern/erstellen keine Playlists in deinem Spotify Account</li>
                        <li>Wir verwenden keinerlei Tracking in der App. Kein Google Analytics und auch kein anderes Tracking Tool, versprochen!</li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Ich hab sonst noch Fragen!
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Dann schreib uns eine Email auf <a href="mailto:info@manydjs.ch">info@manydjs.ch</a>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </v-container>
</template>

<script>
    export default {
        name: "About",
        mounted () {
            this.$store.commit('setTitle', 'About')
        },
        methods: {
            exitRoom() {
                localStorage.removeItem('roomHash')
                localStorage.removeItem('client_id')
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

                location.href = process.env.VUE_APP_CLIENT_URL
            }
        },
        computed: {
            layout() {
                return this.$route.meta.layout || 'AppLayout'
            },
            hostLink() {
                return process.env.VUE_APP_HOST_URL
            }
        }
    }
</script>

<style scoped>

</style>