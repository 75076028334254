<template>
    <v-list-item class="track">
        <v-list-item-avatar v-if="context !== 'album'" @click="$emit('detail')">
            <v-img
                    :alt="`${track.album} cover`"
                    :src="track.album.image"
            ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title>
                {{ track.name }}
                <span class="votes" v-if="getTrackEntry(track) && ! getTrackEntry(track).playing">
                    <span v-if="getTrackEntry(track).votes === 9999998">Up Next</span>
                    <span v-else>
                        {{getTrackEntry(track).votes}} Votes
                    </span>
                </span>
            </v-list-item-title>

            <v-list-item-subtitle v-if="context !== 'album'">
                <template v-if="context === 'artist'">
                    {{ track.album.name }}
                </template>
                <template v-else>
                    {{ track.album.artist.name + ' - ' + track.album.name }}
                </template>
            </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <template v-if="getTrackEntry(track)">
                <v-icon color="primary" v-if="getTrackEntry(track).playing">mdi-volume-high</v-icon>
                <v-icon color="primary" v-else-if="getTrackEntry(track).votes === 9999998">mdi-lock</v-icon>
                <v-btn icon v-else-if="canVote() && hasVoted(getTrackEntry(track).entry_id)">
                    <v-icon color="primary">mdi-check</v-icon>
                </v-btn>
                <v-btn icon v-else-if="canVote()" @click.prevent="vote(getTrackEntry(track).entry_id, 1)">
                    <v-icon color="primary">mdi-plus-one</v-icon>
                </v-btn>
            </template>
            <template v-else-if="canAdd() && !isInQueue(track.track_id)">
                <v-btn icon @click.prevent="addTrack(track)">
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </template>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    import LongPress from 'vue-directive-long-press'

    export default {
        directives: {
            'long-press': LongPress
        },
        name: "Track",
        props: {
            entry: {
                type: Object,
                required: false
            },
            track: {
                type: Object,
                required: true
            },
            context: {
                type: String,
                required: false,
                default: 'Queue'
            }
        },
        data: function () {
            return {
                longPressActive: false
            }
        },
        mounted () {

        },
        computed: {},
        methods: {
            getTrackEntry (track) {
                var entry = null;
                this.$store.state.roomState.queue.forEach(e => {
                    if (e.track_id === track.track_id) {
                        entry = e;
                    }
                })

                return entry;
            }
        }
    }
</script>

<style scoped lang="stylus">
    .v-avatar
        border-radius 0px !important

    .votes
        color gray
</style>