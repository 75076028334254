<template>
    <div>
        <v-list
            dense
        >
            <Track context="queue" @detail="showContextMenu(track.track)" v-for="track in sortedQueue" :key="track.id" :track="track.track" v-if="! track.locked_until" />
        </v-list>

        <v-btn
                class="addSong"
                color="primary"
                dark
                absolute
                bottom
                right
                fab
                to="/search"
                v-if="canAdd()"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-bottom-sheet v-model="sheetOpen">
            <v-list v-if="selectedTrack">
                <v-subheader>{{ selectedTrack.name }}</v-subheader>
                <v-list-item :to="'/artist/' + selectedTrack.album.artist_id">
                    <v-list-item-title>Artist: {{ selectedTrack.album.artist.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="'/album/' + selectedTrack.album.album_id">
                    <v-list-item-title>Album: {{ selectedTrack.album.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import Track from '../components/Elements/Track'
    export default {
        name: "Room",
        components: {Track},
        data: () => ({
            roomHash: null,
            loaded: false,
            selectedTrack: null,
            sheetOpen: false
        }),
        methods: {
            showContextMenu: function(track) {
                this.selectedTrack = track
                this.sheetOpen = true
            },
            canVoteForTrack: function(id) {
                return this.roomState.votes.indexOf(id) === -1
            },
            vote: function(id) {
                this.roomState.votes.push(id)
            }
        },
        computed: {
            roomStatus() {
                return this.$store.state.roomStatus
            }
        },
        mounted () {
            this.$store.commit('setTitle', 'Playlist')
        }
    }
</script>

<style scoped lang="stylus">
    .addSong
        bottom 70px !important
        position fixed

    .v-list
        margin-bottom 60px
</style>